import styled from "styled-components"

import { colorsV2 } from "src/ui/colors"
import {
  ExpandableSection,
  TExpandableSection,
} from "src/ui/ExpandableSection/ExpandableSection"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TExpandableAttentionCard = {
  type: "warning" | "emergency" | "default"
} & Omit<TExpandableSection, "variant">

export function ExpandableAttentionCard({
  title,
  type,
  children,
  ...props
}: TExpandableAttentionCard) {
  // We're wrapping ExpandableSection in order to allow for our AttentionCards
  // to be 'uncontrolled'. If we wan't to create controlled AttentionCards in
  // the future, we will need to rework this.

  const bgColor =
    type === "warning"
      ? colorsV2.systemWarning
      : type === "emergency"
        ? colorsV2.systemError
        : colorsV2.neutralDark

  return (
    <ExpandableSection
      title={
        <MText variant="subtitle" color="contrast">
          {title}
        </MText>
      }
      variant="card"
      summaryProps={{ fgColor: colorsV2.textContrast, bgColor }}
      children={<ChildrenWrapper>{children}</ChildrenWrapper>}
      {...props}
    />
  )
}

const ChildrenWrapper = styled.div`
  padding: ${spacing.M};
`
